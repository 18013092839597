<template>
  <div class="arrow">
    <i class="icon-arrow-down" />
  </div>
</template>
<script>
export default {
  name: 'CardArrow',
  data() {
    return {
      expanded: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.arrow {
    position: absolute;
    right: 2px;
    transition: 0.5s;
    background-color: #F6F6F6;
    border-radius: 1000px;
    padding: 10px;
    height: 44px;
    width: 44px;

  &.expanded {
    transform: rotate(-180deg);
  }
}
</style>
