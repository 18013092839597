<template>
  <button class="d-flex button-no-style" @click.prevent="() => {}">
    <div class="mr-1">
      <lazy-load-image
        :src="`${img}?ts=${now}` || '/images/nrl.svg'"
        @error="imgUrlAlt"
        :alt="title || name"
        class="logo"
      />
    </div>
    <div class="d-flex-column">
      <div>
        <h3 class="title">{{ title || name }}</h3>
      </div>
      <div>
        <h4 id="subtitle" class="subtitle">{{ subtitle || "" }}</h4>
      </div>
      <div v-if="locality && distance" class="locality-container">
        <i class="icon-marker" />
        <span>
          <p id="locality" class="subtitle">{{ `${Math.round(distance)}km from ${locality.charAt(0).toUpperCase() + locality.substr(1).toLowerCase()}` || "" }}</p>
        </span>
      </div>
    </div>
  </button>
</template>

<script>
import LazyLoadImage from "../../LazyLoadImage.vue";

export default {
  name: "CardContent",
  props: {
    title: String,
    subtitle: String,
    locality: String,
    img: String,
    name: String,
    distance: Number,
  },
  components: {
    LazyLoadImage,
  },
  data() {
    return {
      now: Date.now(),
    };
  },
  methods: {
    imgUrlAlt(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.src = "/images/nrl.svg";
    },
  },
};
</script>

<style lang="scss" scoped>
.title,
.subtitle {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-top: 0;
  margin-bottom: 0;
  font-family: $fontMedium;
  color: #222222;
}

.subtitle {
  padding-top: 4px;
  // font-weight: normal !important;
  font-family: $fontRegular;

}

.logo {
  margin-right: 5px;
  height: 60px;
  width: 60px;
}

.icon-marker {
  background-image: url("~@/assets/icons/marker.svg");
  background-repeat: no-repeat;
  background-size: 0.875rem 1.5rem ;
  width: 1.5rem; /* adjust to icon width */
  height: 1.5rem; /* adjust to icon height */
}
.locality-container {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

</style>
