<template>
<div id="map"></div>
</template>

<script>
import map from "lodash/fp/map";

export default {
  name: "Map",
  components: {
  },
  props: {
    renderContent: Array,
    isMobile: Boolean
  },
  watch: {
    localRenderContent: function () {
      this.addEntitiesToMap();
    },
    renderContent: function () {
      this.localRenderContent = this.renderContent;
    },
  },
  computed: {
  },
  methods: {
    handleLocationError(browserHasGeolocation, infoWindow, pos) {
      if (!map || !infoWindow) {
        return;
      }
      infoWindow.setPosition(pos);
      infoWindow.setContent(
        browserHasGeolocation
          ? "Error: The Geolocation service failed."
          : "Error: Your browser doesn't support geolocation."
      );
      infoWindow.open(map);
    },

    getCurrentGeoLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };

            this.currentLocation = pos;
          },
          () => {
            console.log("Unable to get current location");
          }
        );
      } else {
        // If the browser doesn't support geolocation, show an error message
        alert("Geolocation is not supported by this browser.");
      }
      return;
    },

    addLocation(locations) {
      locations.forEach((entity) => {
        let position;
        if (entity.venue) {
          position = {
            lat: entity.venue.address.lat,
            lng: entity.venue.address.lng,
          };
        } else if (entity.contact) {
          position = {
            lat: entity.contact.address.lat,
            lng: entity.contact.address.lng,
          };
        }
        if (position) {
          const infoWindow = new google.maps.InfoWindow({
            content: entity.name,
            ariaLabel: entity.name,
          });
          const marker = new google.maps.Marker({
            position: {
              lat: position.lat,
              lng: position.lng,
            },
            map: this.map,
            title: entity.name,
            icon: {
              path: "M14.1406 11.3407C21.7949 3.68641 34.2049 3.68641 41.8592 11.3407C49.5135 18.995 49.5135 31.405 41.8592 39.0593L27.9999 52.9186L14.1406 39.0593C6.48633 31.405 6.48633 18.995 14.1406 11.3407ZM27.9999 30.8C31.0927 30.8 33.5999 28.2928 33.5999 25.2C33.5999 22.1072 31.0927 19.6 27.9999 19.6C24.9071 19.6 22.3999 22.1072 22.3999 25.2C22.3999 28.2928 24.9071 30.8 27.9999 30.8Z",
              fillColor: "#262626",
              fillOpacity: 1,
              scale: 0.5,
              anchor: new google.maps.Point(28, 56),
            },
          });
          marker.addListener("click", () => {
            this.$emit("scrollToCard", entity);
            infoWindow.open({
              anchor: marker,
              map,
            });
          });
          this.markers.push(marker);
        }
      });
    },

    addEntitiesToMap() {
      if (!document.getElementById("map")) {
        return;
      }
      this.map = new google.maps.Map(document.getElementById("map"), {});
      const locationButton = document.createElement("button");

      const style = {
        ["background-color"]: "#fff",
        border: "0",
        ["border-radius"]: "2px",
        ["box-shadow"]: "0 1px 4px -1px rgba(0, 0, 0, 0.3)",
        margin: "10px",
        padding: "0 0.5em",
        font: "400 18px Roboto, Arial, sans-serif",
        overflow: "hidden",
        height: "40px",
        cursor: "pointer",
      };

      locationButton.textContent = "Pan to Current Location";
      locationButton.setAttribute("id", "current-location-button");
      // Styles for custom control button to pan to current location
      for (const [property, value] of Object.entries(style)) {
        locationButton.style.setProperty(property, value);
      }

      locationButton.onmouseover = () => {
        locationButton.style.background = "rgb(235, 235, 235)";
      };

      locationButton.onmouseout = () => {
        locationButton.style.background = "#fff";
      };

      if(this.isMobile) {
        this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(locationButton);
      } else {
        this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(locationButton);
      }
      locationButton.onclick = () => {
        // Try HTML5 geolocation.
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              this.map.setCenter(pos);
              this.map.setZoom(15);
            },
            () => {
              alert("Couldn't get current position");
            }
          );
        } else {
          // Browser doesn't support Geolocation
          alert("Browser doesn't support Geolocation");
        }
      };

      // search this area button
      const searchAreaButton = document.createElement("button");
      searchAreaButton.textContent = "Search this area";
      searchAreaButton.setAttribute("id", "search-area-button");
      // Styles for custom control button to search this area
      for (const [property, value] of Object.entries(style)) {
        searchAreaButton.style.setProperty(property, value);
      }
      searchAreaButton.onmouseover = () => {
        searchAreaButton.style.background = "rgb(235, 235, 235)";
      };
      searchAreaButton.onmouseout = () => {
        searchAreaButton.style.background = "#fff";
      };

      if(this.isMobile) {
        this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(searchAreaButton);
      } else {
        this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(searchAreaButton);
      }
      searchAreaButton.onclick = () => {
        // Create a geoCoder object
        var geoCoder = new google.maps.Geocoder();

        // Get the center of the current visible map
        var center = this.map.getCenter();

        const data = this;

        // Call the geoCoder to get the address information for the center of the map
        geoCoder.geocode({ location: center }, function (results, status) {
          if (status === "OK") {
            // Loop through the results to find the postcode
            for (const result of results) {
              const addressComponent = result.address_components.find((component) =>
                component.types.includes("postal_code")
              );
              if (addressComponent) {
                const postcode = addressComponent.long_name;
                data.searchInput = postcode;
                data.$emit("updateSearchInput", postcode);
                break;
              }
            }
          } else {
            console.log("GeoCoder failed due to: " + status);
          }
        });
      };

      if (this.localRenderContent.length > 0) {
        // find first element in this.renderContent with address.lat and address.lng is defined
        let center;
        if(this.localRenderContent[0]?.venue?.address) {
          center = this.localRenderContent[0]?.venue?.address
        } else if(this.localRenderContent[0]?.contact?.address) {
          center = this.localRenderContent[0]?.contact?.address;
        }
        if (center && center.lat && center.lng) {
          this.map.setCenter({ lat: center.lat, lng: center.lng });
        } else {
          // Default to NRL RLC in the worst case where there are no results in selected criteria
          this.map.setCenter({ lat: -33.859953, lng: 151.256649 });
        }
      } else {
        // Default to NRL RLC in the worst case where there are no results in selected criteria
        this.map.setCenter({ lat: -33.859953, lng: 151.256649 });
      }
      this.map.setZoom(10);

      this.markers = [];
      const markerCluster = new MarkerClusterer(this.map, this.markers, {
        gridSize: 50,
        maxZoom: 15,
        imagePath:
          "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
      });
      if (this.currentLocation) {
        const infoWindow = new google.maps.InfoWindow({
          content: "Your location",
          ariaLabel: "Your location",
        });
        const marker = new google.maps.Marker({
          position: { lat: this.currentLocation.lat, lng: this.currentLocation.lng },
          map: this.map,
          labelAnchor: new google.maps.Point(200, 100),
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 10,
            fillColor: "#0088FF",
            fillOpacity: 1,
            strokeColor: "white",
            strokeWeight: 2,
          },
        });
        marker.addListener("click", () => {
          infoWindow.open({
            anchor: marker,
            map,
          });
        });
      }
      if (this.localRenderContent?.length > 0) {
        this.addLocation(this.localRenderContent);
      }
      markerCluster.addMarkers(this.markers);
    },
  },
  mounted() {
    this.getCurrentGeoLocation();
    this.addEntitiesToMap();
  },
  data() {
    return {
      map: undefined,
      markers: [],
      infoWindow: undefined,
      currentLocation: undefined,
      localRenderContent: this.renderContent
    };
  },
};
</script>

<style lang="scss">
.text-days::before {
  content: var(--content);
  color: var(--color);
  text-transform: uppercase;
  position: absolute;
  font-family: $fontRegular;
  font-size: 0.875rem;
  top: 10px;
  left: 15px;
  size: 0;
  z-index: 1;
}
</style>

<style lang="scss" scoped>

#map {
  height: 90vh;
  /* The height is 400 pixels */
  width: 100%;
  /* The width is the width of the web page */
  position: relative;
}
.custom-map-control-button {
  background-color: #fff;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
  margin: 10px;
  padding: 0 0.5em;
  font: 400 18px Roboto, Arial, sans-serif;
  overflow: hidden;
  height: 40px;
  cursor: pointer;
}
.custom-map-control-button:hover {
  background: rgb(235, 235, 235);
}
</style>
